import Fetch from "network/Fetch";
import { call, put, takeEvery } from "redux-saga/effects";
import { GET_REVIEW_FAIL, GET_REVIEW_START, GET_REVIEW_SUCCESS, GET_REVIEWS_FAIL, GET_REVIEWS_START, GET_REVIEWS_SUCCESS, REVIEW_RATING_FAIL, REVIEW_RATING_START, REVIEW_RATING_SUCCESS, UPSERT_REVIEW_FAIL, UPSERT_REVIEW_START, UPSERT_REVIEW_SUCCESS } from "store/actions";
import { GET_REVIEW, GET_REVIEWS, REVIEW_RATING, UPSERT_REVIEW } from "store/constant";

function* getReviews(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'GET', GET_REVIEWS, action.params);
        if (response.status === 200) {
            yield put({ type: GET_REVIEWS_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_REVIEWS_FAIL });
        }
    } catch (err) {
        yield put({ type: GET_REVIEWS_FAIL });
    }
}

function* getReview(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'GET', GET_REVIEW, action.params);
        if (response.status === 200) {
            yield put({ type: GET_REVIEW_SUCCESS, data: response.data });
        } else {
            yield put({ type: GET_REVIEW_FAIL });
        }
    } catch (err) {
        yield put({ type: GET_REVIEW_FAIL });
    }
}

function* upsertReview(action) {
    try {
        const response = yield call(new Fetch().axiosFormDataRequest, 'POST', UPSERT_REVIEW, action.params, action.images);
        if (response.status === 200) {
            action.callBack('success', response.data);
            yield put({ type: UPSERT_REVIEW_SUCCESS, data: response.data });
        } else {
            action.callBack('error', response.message);
            yield put({ type: UPSERT_REVIEW_FAIL });
        }
    } catch (err) {
        action.callBack('error', err.message);
        yield put({ type: UPSERT_REVIEW_FAIL });
    }
}

function* reviewRating(action) {
    try {
        const response = yield call(new Fetch().fetchRequest, 'POST', REVIEW_RATING, action.params);
        if (response.status === 200) {
            action.callBack('success', response.data);
            yield put({ type: REVIEW_RATING_SUCCESS, data: response.data });
        } else {
            action.callBack('error', response.message);
            yield put({ type: REVIEW_RATING_FAIL });
        }
    } catch (err) {
        action.callBack('error', err.message);
        yield put({ type: REVIEW_RATING_FAIL });
    }
}

export function* watchReviews() {
    yield takeEvery(GET_REVIEWS_START, getReviews);
    yield takeEvery(GET_REVIEW_START, getReview);
    yield takeEvery(UPSERT_REVIEW_START, upsertReview);
    yield takeEvery(REVIEW_RATING_START, reviewRating);
}